/* CountrySelection.css */
.country-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    ;
}
.terra {
    width: 200px;
    height: auto;
    margin-bottom: 2rem;
}

h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
}  
.country-buttons {
    display: flex;
    gap: 2rem;
}

.country-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: none;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: transform 0.2s;
}

.country-button:hover {
    transform: scale(1.05);
}
    
.country-flag {
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;
}