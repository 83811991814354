.container {
    margin-top: 20px;
}

.table {
    margin-top: 20px;
}

.table-cell {
    padding: 16px;
}

.button {
    margin-left: 8px;
}