.tarefas-container {
    position: fixed;
    top: 72px; /* Ajusta según la altura de tu navbar */
    right: 0;
    width: 300px; /* Ajusta el ancho según tus necesidades */
    height: calc(100% - 72px); /* Resta la altura de la navbar */
    overflow-y: auto; /* Permite scroll si hay muchas notificaciones */
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Opcional */
    padding: 16px;
    z-index: 1000; /* Asegura que se muestre sobre otros elementos */
}
.home-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    
    background-size: cover;
}

.home-content {
    text-align: center;
    color: white;
    animation: fadeIn 3s ease-out;
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
}

.home-title {
    font-size: 5rem;
    margin: 0;
    animation: dropIn 1s ease-out, disappear 7s ease-in-out forwards;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 
    font-weight: bold;
}

.rocket {
    position: absolute;
    width: 100px; /* Ajusta el tamaño según sea necesario */
    height: 100px; /* Ajusta el tamaño según sea necesario */
    background: url('../../utils/ROCKET.gif') no-repeat center center;
    background-size: contain;
    animation: flyAround 8s ease-in-out forwards;
}

@keyframes flyAround {
    0% {
        transform: translate(-250px, -200px) rotate(0deg);
    }
    25% {
        transform: translate(500px, 50px) rotate(90deg);
    }
    50% {
        transform: translate(750px, -200px) rotate(180deg);
    }
    75% {
        transform: translate(-1000px, 50px) rotate(270deg);
    }
    100% {
        transform: translate(-1000px, -150px) rotate(360deg);
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes dropIn {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes disappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Estilos responsivos para pantallas pequeñas */
@media (max-width: 768px) {
    .content-container {
        grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
        gap: 16px; /* Reduce el espaciado entre los gráficos */
        padding: 12px; /* Reduce el padding interno */
    }

    .chart-container,
    .notifications-container {
        padding: 12px; /* Reduce el padding interno */
        height: 250px; /* Altura fija para los gráficos en pantallas pequeñas */
    }

    .home-title {
        font-size: 1.5rem; /* Reduce el tamaño del título */
        text-align: center; /* Centra el texto */
    }
}




