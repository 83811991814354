.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: relative;
    z-index: 2;
}
.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo img {
    height: 40px;
}

.nav-links {
    display: flex;
    gap: 1rem;
    position: relative; /* Asegura que los submenús se posicionen correctamente */
}

.nav-link {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease;
    position: relative; /* Asegura que los submenús se posicionen correctamente */
}

.nav-link.selected {
    background-color: #dfcfcfa3; 
    color: #ffffff; 
    border-radius: 5px;
}

.nav-link:hover {
    background-color:#dfcfcfa3;
    border-radius: 5px;
    color: #000;
}
.navbar-user-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.navbar-user-icon {
    margin-right: 5px;
    color: #ffffff;
}

.navbar-user-name {
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff;
}

.nav-icon {
    margin-right: 0.5rem;
}

.mobile-menu-icon {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

.sub-menu {
    display: none; /* Oculta los submenús por defecto */
    flex-direction: column;
    position: absolute;
    border-radius: 5px;
    left: 0;
    font-weight: bold;
    top: 100%; /* Posiciona los submenús debajo del menú principal */
    background-color: #dfcfcfca;
    z-index: 3; /* Asegura que los submenús se muestren por encima */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
    white-space: nowrap;
}

.nav-link:hover .sub-menu {
    display: flex; /* Muestra los submenús al pasar el ratón sobre el menú principal */
    opacity: 1;
    transform: translateY(0);
}

.sub-menu-link {
    padding: 10px;
    text-decoration: none;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sub-menu-link:hover {
    background-color: var(--navbar-hover-color);
    color: #fff;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 60px;
        background-color: var(--navbar-color);
        left: 0;
        padding: 1rem 0;
    }

    .nav-links-mobile {
        display: flex;
    }

    .nav-link {
        padding: 1rem;
        width: 100%;
        text-align: center;
    }

    .mobile-menu-icon {
        display: block;
    }

    .sub-menu {
        display: none; /* Oculta los submenús por defecto */
        flex-direction:column;
        position: relative; /* Ajusta la posición de los submenús en dispositivos móviles */
        margin-left: 10px;
        font-weight: bold;
        background-color: #dfcfcfca;
        border: #ffffff;
        z-index: 3;
        border-radius: 5px;
    }
    .nav-link:hover{
        color: #000;
    }

    .nav-link:hover .sub-menu {
        display: flex; /* Muestra los submenús al pasar el ratón sobre el menú principal */
    }

    .sub-menu-link {
        padding: 10px;
        text-decoration: none;
        color: #000;
    }

    .sub-menu-link:hover {
        background-color: var(--navbar-hover-color);
    }
}

